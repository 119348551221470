<template>
  <!-- 套餐管理 -->
  <div>
    <div class="flex">
      <el-form :model="listQuery" :inline="true">
        <el-form-item label="类别：">
          <el-select v-model="listQuery.type">
            <el-option
              v-for="(item, index) in typeList"
              :key="index"
              :label="item.target"
              :value="item.target"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="listQuery.name"
            placeholder="请输入主标题/小标题"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" class="mr10" @click="addClick"
          >新增</el-button
        >
        <el-dropdown>
          <el-button type="primary" class="mr10">导入数据</el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="exportClick"
              >导入Excel</el-dropdown-item
            >
            <a
              href="/套餐模板.xlsx"
              download="套餐模板.xlsx"
              style="
                color: #606266;
                display: block;
                text-align: center;
                font-size: 14px;
                text-decoration-line: none;
              "
              >下载模板</a
            >
          </el-dropdown-menu>
        </el-dropdown>

        <el-button type="danger" @click="batchDetele">批量删除</el-button>
        <el-button type="primary" @click="batchGrounding(1)"
          >批量上架</el-button
        >
        <el-button type="warning" @click="batchGrounding(0)"
          >批量下架</el-button
        >
      </div>
    </div>
    <el-table
      :data="tableData"
      @select="onSelect"
      @select-all="onSelectAll"
      row-key="rowKey"
      ref="multipleTable"
      style="width: 100%"
    >
      <el-table-column type="selection" width="45"></el-table-column>
      <el-table-column type="index" label="序号" width=""></el-table-column>
      <el-table-column prop="mealUrl" label="封面图" width="">
        <template v-slot="scope">
          <el-image
            :src="
              projectName +
              '/common/backstage/file/fileDownLoad?filePath=' +
              encodeURIComponent(scope.row.mealUrl)
            "
            class="picOne"
            :preview-src-list="[
              projectName +
                '/common/backstage/file/fileDownLoad?filePath=' +
                encodeURIComponent(scope.row.mealUrl),
            ]"
          >
          </el-image>
          <!-- <img :src="projectName + '/common/backstage/file/fileDownLoad?filePath=' + encodeURIComponent(scope.row.mealUrl)" class="picOne" /> -->
        </template>
      </el-table-column>
      <el-table-column prop="type" label="类别" width=""></el-table-column>
      <el-table-column
        prop="mealName"
        label="主标题"
        width=""
      ></el-table-column>
      <el-table-column
        prop="subtitle"
        label="小标题"
        width=""
      ></el-table-column>
      <el-table-column prop="price" label="价格(元)" width=""></el-table-column>
      <el-table-column prop="mealDetailUrl" label="详情图" width="">
        <template v-slot="scope">
          <el-image
            :src="
              projectName +
              '/common/backstage/file/fileDownLoad?filePath=' +
              encodeURIComponent(scope.row.mealDetailUrl)
            "
            class="picTwo"
            :preview-src-list="[
              projectName +
                '/common/backstage/file/fileDownLoad?filePath=' +
                encodeURIComponent(scope.row.mealDetailUrl),
            ]"
          >
          </el-image>
          <!-- <img
            :src="
              projectName +
              '/common/backstage/file/fileDownLoad?filePath=' +
              encodeURIComponent(scope.row.mealDetailUrl)
            "
            class="picTwo"
          /> -->
        </template>
      </el-table-column>
      <!-- <el-table-column prop="updateTime" label="上传时间" width="160"></el-table-column> -->
      <el-table-column prop="isHotMeal" label="是否为热门套餐" width="">
        <template v-slot="scope">
          <el-switch
            v-model="scope.row.isHotMeal"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="isHotMealChange(scope.row)"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="mealStatus" label="状态" width="100">
        <template v-slot="scope">
          <span
            :class="scope.row.mealStatus == 0 ? 'yellowcolor' : 'bluecolor'"
            >{{ scope.row.mealStatus == 1 ? "上架" : "下架" }}</span
          >
        </template>
      </el-table-column>
      <el-table-column prop="date" label="操作" width="220">
        <template v-slot="scope">
          <span v-if="scope.row.isTop == 1" style="margin-right: 10px"
            >置顶</span
          >
          <el-button type="text" @click="toppingClick(scope.row)" v-else
            >置顶</el-button
          >
          <el-button type="text" @click="editClick(scope.row)">编辑</el-button>
          <el-button type="text" @click="viewClick(scope.row)">查看</el-button>
          <el-button
            type="text"
            @click="grounding(scope.row)"
            :class="scope.row.mealStatus == 1 ? 'yellowcolor' : ''"
            >{{ scope.row.mealStatus == 0 ? "上架" : "下架" }}</el-button
          >
          <el-button
            type="text"
            class="redcolor"
            @click="deleteClick(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="footer">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="listQuery.current"
        :page-size="listQuery.size"
        layout="total, prev, pager, next, jumper"
        :total="listQuery.total"
        ref="pagination"
      >
      </el-pagination>
    </div>

    <el-dialog
      title="数据导入"
      :visible.sync="rechargeVisible"
      :show-close="false"
      :close-on-click-modal="false"
      @closed="closed"
      width="20%"
    >
      <div>
        <input type="file" @click="inputClick" @change="fileSucess" />
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="rechargeVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="importClick"
          :loading="loginBtnDisabled"
          :disabled="loginBtnDisabled"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 批量删除 -->
    <el-dialog title="批量删除" :visible.sync="dialogVisible" width="320px">
      <div class="dialog-con" v-if="isJudge == 1">
        有未下架的套餐，不能删除！
      </div>
      <div class="dialog-con" v-if="isJudge == 2">
        您已勾选{{ this.checkData.length }}条数据，确定删除？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteArr">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 批量上下架 -->
    <el-dialog
      :title="titleName"
      :close-on-click-modal="false"
      :visible.sync="upperLowerDialogVisible"
      width="350px"
    >
      <div
        class="dialog-con"
        v-if="titleName == '批量上架' && isUpperLower == '1'"
      >
        勾选数据中有“下架”状态的套餐，请确认！
      </div>
      <div
        class="dialog-con"
        v-if="titleName == '批量下架' && isUpperLower == '1'"
      >
        勾选数据中有“上架”状态的套餐，请确认！
      </div>
      <div class="dialog-con" v-if="isUpperLower == '2'">
        您已勾选{{ this.checkData.length }}条数据，确定进行{{
          titleName == "批量上架" ? "上架" : "下架"
        }}操作？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="upperLowerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="upperLowerArr">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  packagePage,
  categorylist,
  packagedelete,
  ismeal,
  isMealStatus,
  mealExport,
  mealTop,
  upperLowerArr,
  mealDeletebranch,
} from "../../api/index";
import { projectName } from "../../util/config";
export default {
  data() {
    return {
      projectName: projectName,
      listQuery: {
        name: "",
        type: "",
        size: 10,
        current: 1,
        total: 0,
      },
      typeList: [
        // {name:'家居灯',value:1},
        // {name:'无主灯/智能灯',value:2},
        // {name:'商照',value:3},
        // {name:'户外',value:4},
        // {name:'光源',value:5},
        // {name:'开关插座',value:6},
      ],
      tableData: [],
      rechargeVisible: false,
      loginBtnDisabled: false,
      uploadObj: {
        file: "",
      },
      dialogVisible: false,
      upperLowerDialogVisible: false, //批量上下架
      printData: {}, //单选
      checkData: [], //勾选的数据
      dataArr: [], //用来判断数据是否为上架或下架
      isJudge: "",
      titleName: "", //上下架名称
      isUpperLower: "",
    };
  },
  mounted() {
    if (this.$route.query.current) {
      this.listQuery.current = this.$route.query.current - 0;
      this.$nextTick(() => {
        this.$refs.pagination.internalCurrentPage = this.listQuery.current;
      });
    }
    this.getTableData();
    this.getTypeData();
  },
  methods: {
    // 单个勾选 mealStatus 0下架，1上架
    onSelect(selection, row) {
      // console.log(selection,"selectionselection")
      this.dataArr = selection;
      for (let i = 0; i < this.dataArr.length; i++) {
        if (this.dataArr[i].mealStatus == 1) {
          this.isJudge = 1;
          break;
        } else {
          this.isJudge = 2;
        }
      }
      if (this.printData[row.mealId]) {
        delete this.printData[row.mealId];
      } else {
        this.printData[row.mealId] = row;
      }
      // console.log(this.printData,"勾选改变之前的数据")
      this.checkData = Object.keys(this.printData);
      // console.log(this.checkData,"this.checkData处理之后的数据")
    },
    // 全选 mealStatus 0下架，1上架
    onSelectAll(selection) {
      // console.log(selection,"selectionselection")
      this.dataArr = selection;
      for (let i = 0; i < this.dataArr.length; i++) {
        if (this.dataArr[i].mealStatus == 1) {
          this.isJudge = 1;
          break;
        } else {
          this.isJudge = 2;
        }
      }
      if (selection.length) {
        selection.forEach((row) => {
          if (!this.printData[row.mealId]) {
            this.printData[row.mealId] = row;
          }
        });
      } else {
        this.tableData.forEach((row) => {
          delete this.printData[row.mealId];
        });
      }
      // console.log(this.printData,"勾选改变之前的数据")
      this.checkData = Object.keys(this.printData);

      // console.log(this.checkData,"this.printData22222")
    },
    checkRows() {
      var that = this;
      const keys = Object.keys(this.printData);
      const rows = this.tableData.filter((row) => {
        return keys.includes(String(row.mealId));
      });
      rows.forEach((row) => {
        that.$nextTick(function () {
          that.$refs.multipleTable.toggleRowSelection(row, true);
        });
      });
    },
    // 批量删除
    batchDetele() {
      if (this.checkData.length <= 0) {
        this.$message.warning("请先勾选数据！");
      } else {
        this.dialogVisible = true;
      }
    },
    //删除确定
    deleteArr() {
      mealDeletebranch({ mealIds: this.checkData }).then((res) => {
        if (res.code == 1) {
          this.$message.success("删除成功！");
          this.dialogVisible = false;
          this.checkData = [];
          this.printData = {};
          this.dataArr = [];
          this.getTableData();
        }
      });
    },
    //批量上架1 下架0
    batchGrounding(num) {
      console.log(this.dataArr, "dataArrdataArrdataArr");
      if (num == 1) {
        this.titleName = "批量上架";
      } else {
        this.titleName = "批量下架";
      }
      for (let i = 0; i < this.dataArr.length; i++) {
        if (num == 1) {
          if (this.dataArr[i].mealStatus == 1) {
            this.isUpperLower = "1";
            break;
          } else {
            this.isUpperLower = "2";
          }
        } else if (num == 0) {
          if (this.dataArr[i].mealStatus == 0) {
            this.isUpperLower = "1";
            break;
          } else {
            this.isUpperLower = "2";
          }
        }
      }
      if (this.checkData.length <= 0) {
        this.$message.warning("请先勾选数据！");
      } else {
        this.upperLowerDialogVisible = true;
      }
    },
    upperLowerArr() {
      let type = null;
      if (this.titleName == "批量上架") {
        type = 1;
      } else {
        type = 0;
      }
      let data = {
        type: type,
        mealIds: this.checkData,
      };
      upperLowerArr(data).then((res) => {
        if (res.code == 1) {
          this.$message.success("操作成功！");
          this.upperLowerDialogVisible = false;
          this.checkData = [];
          this.printData = {};
          this.dataArr = [];
          this.getTableData();
        }
      });
    },
    toppingClick(row) {
      this.$confirm("是否置顶该套餐？", "提示", {
        confirmButtonText: "确定",
        concelButtonText: "取消",
        type: "warning",
      }).then(() => {
        mealTop({ mealId: row.mealId }).then((res) => {
          if (res.code == 1) {
            this.$message({
              type: "success",
              message: "操作成功！",
            });
            this.getTableData();
          }
        });
      });
    },
    exportClick() {
      this.rechargeVisible = true;
    },
    closed() {
      history.go(0);
    },
    // 点击文件的时候 先清空
    inputClick(event) {
      console.log(event, "event1123");
      event.target.value = "";
      this.uploadObj.file = "";
    },
    // 导入change事件
    fileSucess(event) {
      console.log(event, "eventeventevent");
      this.uploadObj.file = event.target.files[0];
    },
    importClick() {
      if (!this.uploadObj.file) {
        this.$message.info("请选择文件");
        return;
      }
      let files = new FormData();
      files.append("file", this.uploadObj.file);
      let data = {
        identification: this.identification,
      };
      console.log(data, "ddddd");
      this.loginBtnDisabled = true;
      mealExport(files)
        .then((res) => {
          if (res.code == 1) {
            this.$message.success("导入成功！");
            this.dialogVisible = false;
            history.go(0);
          } else {
            // let msg = res.message
            // alert(msg)
            // this.loginBtnDisabled = false;
          }
        })
        .catch(() => {
          this.$message.error(res.msg);
        });
    },
    getTypeData() {
      let data = {
        current: 1,
        size: 1000,
        type: 1,
      };
      categorylist(data).then((res) => {
        if (res.code == 1) {
          this.typeList = res.data.records || [];
        }
      });
    },
    getTableData() {
      packagePage({ ...this.listQuery }).then((res) => {
        if (res.code == 1) {
          this.tableData = res.data.records || [];
          this.listQuery.total = res.data.total || 0;
        }
      });
    },
    // 是否为热门套餐
    isHotMealChange(row) {
      console.log(row, "4444");
      let data = {
        mealId: row.mealId,
        isHotMeal: row.isHotMeal,
      };
      console.log(data, "fff");
      ismeal(data).then((res) => {
        if (res.code == 1) {
          this.$message.success("操作成功！");
          this.getTableData();
        }
      });
    },
    addClick() {
      this.$router.push({ name: "packegeProduct"});
    },
    editClick(row) {
      this.$router.push({
        name: "packegeProductEdit",
        query: {
          mealId: row.mealId,
          current: this.listQuery.current
        },
      });
    },
    viewClick(row) {
      this.$router.push({
        name: "packegeProductView",
        query: {
          mealId: row.mealId,
          isView: true,
          current: this.listQuery.current
        },
      });
    },
    // 是否上架 mealStatus 0下架 1上架
    grounding(row) {
      let mealStatus = row.mealStatus == 0 ? "上架" : "下架";
      this.$confirm(`是否${mealStatus}该套餐？`, "提示", {
        confirmButtonText: "确定",
        concelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let mealStatus = row.mealStatus == 0 ? 1 : 0;
        let data = {
          mealId: row.mealId,
          mealStatus: mealStatus,
        };
        isMealStatus(data).then((res) => {
          if (res.code == 1) {
            this.$message({
              type: "success",
              message: "操作成功！",
            });
            this.getTableData();
          }
        });
      });
    },
    handleCurrentChange(current) {
      this.listQuery.current = current;
      this.getTableData();
    },
    search() {
      this.listQuery.current = 1;
      this.getTableData();
    },
    reset() {
      this.listQuery = {
        name: "",
        type: "",
        size: 10,
        current: 1,
        total: 0,
      };
      this.getTableData();
    },
    deleteClick(row) {
      this.$confirm("是否确认删除此套餐？", "提示", {
        confirmButtonText: "确定",
        concelButtonText: "取消",
        type: "warning",
      }).then(() => {
        packagedelete({ id: row.mealId }).then((res) => {
          if (res.code == 1) {
            this.$message({
              type: "success",
              message: "删除成功！",
            });
            this.getTableData();
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.flex {
  display: flex;
  justify-content: space-between;
}
.footer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
.picOne {
  width: 100px;
  height: 50px;
}
.picTwo {
  width: 50px;
  height: 100px;
}
.redcolor {
  color: red;
}
.yellowcolor {
  color: #ffb129;
}
.bluecolor {
  color: #198cff;
}
.mr10 {
  margin-right: 10px;
}

.dialog-con {
  text-align: center;
  color: red;
  font-size: 15px;
}
</style>