import request from "@/base/util/request.js";

// POST/qixianggongyingpingtai_api​/qixiang​/top​/image​/list
// 图片跳转列表
export const list = (data) => {
    return request({
        url: "/qixiang/top/image/list",
        method: "post",
        data,
    })
}

// POST/qixianggongyingpingtai_api​/qixiang​/top​/image​/add
// 新增
export const add = (data) => {
    return request({
        url: "/qixiang/top/image/add",
        method: "post",
        data,
    })
}
// PUT/qixianggongyingpingtai_api​/qixiang​/top​/image​/edit
// 编辑
export const edit = (data) => {
    return request({
        url: "/qixiang/top/image/edit",
        method: "put",
        data,
    })
}

// DELETE/qixianggongyingpingtai_api​/qixiang​/top​/image​/delete
// 删除
export const deleteAdvert = (params) => {
    return request({
        url: "/qixiang/top/image/delete",
        method: "delete",
        params,
    })
}


// -------------类别设置--------------

// POST/qixianggongyingpingtai_api​/qixiang​/produce​/categoryList​/list
// 产品类别列表
export const categorylist = (data) => {
    return request({
        url: "/qixiang/produce/categoryList/list",
        method: "post",
        data,
    })
}
// GET/qixianggongyingpingtai_api​/qixiang​/produce​/categoryList​/details
// 产品类别详情，编辑回显
export const categorydetails = (params) => {
    return request({
        url: "/qixiang/produce/categoryList/details",
        method: "get",
        params,
    })
}

// POST/qixianggongyingpingtai_api​/qixiang​/produce​/categoryList​/add
// 产品类别新增
export const categoryadd = (data) => {
    return request({
        url: "/qixiang/produce/categoryList/add",
        method: "post",
        data,
    })
}
// PUT/qixianggongyingpingtai_api​/qixiang​/produce​/categoryList​/edit
// 产品类别编辑
export const categoryedit = (data) => {
    return request({
        url: "/qixiang/produce/categoryList/edit",
        method: "put",
        data,
    })
}

// DELETE/qixianggongyingpingtai_api​/qixiang​/produce​/categoryList​/remove
// 产品类别删除
export const remove = (params) => {
    return request({
        url: "/qixiang/produce/categoryList/remove",
        method: "delete",
        params,
    })
}

// -----------------产品管理-----------------------
// POST/qixianggongyingpingtai_api​/qixiang​/product​/select​/page
// 查询产品
export const page = (data) => {
    return request({
        url: "/qixiang/product/select/page",
        method: "post",
        data,
    })
}

// POST/qixianggongyingpingtai_api​/qixiang​/product​/add
// 新增产品
export const productadd = (data) => {
    return request({
        url: "/qixiang/product/add",
        method: "post",
        data,
    })
}

// GET/qixianggongyingpingtai_api​/qixiang​/product​/edit​/detail
// 详情[编辑时回显]
export const productdetail = (params) => {
    return request({
        url: "/qixiang/product/edit/detail",
        method: "get",
        params,
    })
}

// POST/qixianggongyingpingtai_api​/qixiang​/product​/edit
// 编辑
export const productedit = (data) => {
    return request({
        url: "/qixiang/product/edit",
        method: "post",
        data,
    })
}

// DELETE/qixianggongyingpingtai_api​/qixiang​/product​/delete​/product
// 删除【删除商品列表】】
export const productdelete = (params) => {
    return request({
        url: "/qixiang/product/delete/product",
        method: "delete",
        params,
    })
}


// --------------------照明方案管理--------------------
// POST/qixianggongyingpingtai_api​/qixiang​/lighting​/scheme​/page
// 查询服务方案
export const lightingpage = (data) => {
    return request({
        url: "/qixiang/lighting/scheme/page",
        method: "post",
        data,
    })
}

// POST/qixianggongyingpingtai_api​/qixiang​/lighting​/scheme​/add
// 新增
export const lightingAdd = (data) => {
    return request({
        url: "/qixiang/lighting/scheme/add",
        method: "post",
        data,
    })
}

// POST/qixianggongyingpingtai_api​/qixiang​/lighting​/scheme​/edit
// 编辑
export const lightingEdit = (data) => {
    return request({
        url: "/qixiang/lighting/scheme/edit",
        method: "post",
        data,
    })
}

// DELETE/qixianggongyingpingtai_api​/qixiang​/lighting​/scheme​/delete
// 删除
export const lightingDelete = (params) => {
    return request({
        url: "/qixiang/lighting/scheme/delete",
        method: "delete",
        params,
    })
}

// GET/qixianggongyingpingtai_api​/qixiang​/lighting​/scheme​/details
// 详情[编辑返显]
export const lightingDetails = (params) => {
    return request({
        url: "/qixiang/lighting/scheme/details",
        method: "get",
        params,
    })
}


// --------------------套餐管理----------------------
// POST/qixianggongyingpingtai_api​/qixiang​/meal​/select​/page
// 查询套餐
export const packagePage = (data) => {
    return request({
        url: "/qixiang/meal/select/page",
        method: "post",
        data,
    })
}

// POST/qixianggongyingpingtai_api​/qixiang​/meal​/add
// 新增套餐
export const packageAdd = (data) => {
    return request({
        url: "/qixiang/meal/add",
        method: "post",
        data,
    })
}

// PUT/qixianggongyingpingtai_api​/qixiang​/meal​/edit
// 编辑
export const packageEdit = (data) => {
    return request({
        url: "/qixiang/meal/edit",
        method: "put",
        data,
    })
}

// POST/qixianggongyingpingtai_api​/qixiang​/meal​/select​/particulars
// 查看详情
export const particulars = (data) => {
    return request({
        url: "/qixiang/meal/particulars",
        method: "post",
        data,
    })
}

// DELETE/qixianggongyingpingtai_api​/qixiang​/meal​/delete
// 删除
export const packagedelete = (params) => {
    return request({
        url: "/qixiang/meal/delete",
        method: "delete",
        params,
    })
}


// GET/qixianggongyingpingtai_api​/qixiang​/product​/select​/page​/son
// 根据一级分类id查询二级分类
export const typeson = (params) => {
    return request({
        url: "/qixiang/product/select/page/son",
        method: "get",
        params,
    })
}

// POST/qixianggongyingpingtai_api​/qixiang​/user​/message
export const message = (data) => {
    return request({
        url: "/qixiang/user/message",
        method: "post",
        data,
    })
}


// PUT/qixianggongyingpingtai_api​/qixiang​/top​/image​/edit​/status
// 编辑广告状态
export const adStatus = (data) => {
    return request({
        url: "/qixiang/top/image/edit/status",
        method: "put",
        data,
    })
}

// PUT/qixianggongyingpingtai_api​/qixiang​/meal​/edit​/hot​/meal
// 编辑热门套餐
export const ismeal = (data) => {
    return request({
        url: "/qixiang/meal/edit/hot/meal",
        method: "put",
        data,
    })
}

// POST/qixianggongyingpingtai_api​/qixiang​/product​/import​/product
// 批量新增商品
export const product = (data,params) => {
    return request({
        url: "/qixiang/product/import/product",
        method: "post",
        data,
        params
    })
}

// PUT/qixianggongyingpingtai_api​/qixiang​/product​/edit​/productStatus
// 编辑商品上下架状态(列表商品上下架编辑)
export const isProductStatus = (data) => {
    return request({
        url: "/qixiang/product/edit/productStatus",
        method: "put",
        data,
    })
}


// POST​/qixianggongyingpingtai_api​/qixiang​/meal​/import​/meal
// 上传套餐
export const mealExport = (data,params) => {
    return request({
        url: "/qixiang/meal/import/meal",
        method: "post",
        data,
        params
    })
}

// PUT/qixianggongyingpingtai_api​/qixiang​/meal​/edit​/mealStatus
// 编辑套餐上下架状态
export const isMealStatus = (data) => {
    return request({
        url: "/qixiang/meal/edit/mealStatus",
        method: "put",
        data,
    })
}

// POST/qixianggongyingpingtai_api​/qixiang​/product​/top​/product
// 商品置顶
export const productTop = (params) => {
    return request({
        url: "/qixiang/product/top/product",
        method: "post",
        params,
    })
}

// POST/qixianggongyingpingtai_api​/qixiang​/meal​/top​/meal
// 套餐置顶
export const mealTop = (params) => {
    return request({
        url: "/qixiang/meal/top/meal",
        method: "post",
        params,
    })
}

//批量删除
// POST/qixianggongyingpingtai_api​/qixiang​/product​/product​/delete​/branch
// 批量删除商品
export const branchDeleteArr = (data) => {
    return request({
        url: "/qixiang/product/product/delete/branch",
        method: "post",
        data,
    })
}

// POST/qixianggongyingpingtai_api​/qixiang​/product​/product​/status​/branch
// 批量上架/下架商品
export const branchuUpperLower = (data) => {
    return request({
        url: "/qixiang/product/product/status/branch",
        method: "post",
        data,
    })
}

// POST/qixianggongyingpingtai_api​/qixiang​/meal​/meal​/status​/branch
// 批量上架/下架套餐
export const upperLowerArr = (data) => {
    return request({
        url: "/qixiang/meal/meal/status/branch",
        method: "post",
        data,
    })
}

// POST/qixianggongyingpingtai_api​/qixiang​/meal​/meal​/delete​/branch
// 批量删除套餐
export const mealDeletebranch = (data) => {
    return request({
        url: "/qixiang/meal/meal/delete/branch",
        method: "post",
        data,
    })
}

// GET
// ​/qixianggongyingpingtai_api​/qixiang​/product​/storage​/order​/page
// getOrderPage 订单记录
export const orderpage= (params) => {
    return request({
        url: "/qixiang/product/storage/order/page",
        method: "get",
        params,
    })
}


// GET
// ​/qixianggongyingpingtai_api​/qixiang​/product​/storage​/order​/detail
// orderDetail  订单详情
export const orderdetail= (params) => {
    return request({
        url: "/qixiang/product/storage/order/detail",
        method: "get",
        params,
    })
}

// /qixianggongyingpingtai_api/qixiang/product/select/placeList
export const placeList= (params) => {
    return request({
        url: "/qixiang/product/select/placeList",
        method: "get",
        params,
    })
}

// POST
// ​/qixianggongyingpingtai_api​/qixiang​/product​/product​/export
// 导出商品信息
export const productexport= (data) => {
    return request({
        url: "/qixiang/product/product/export",
        method: "post",
        data,
        responseType: 'blob'
    })
}

// POST
// ​/qixianggongyingpingtai_api​/qixiang​/product​/lable​/branch​/change
// 批量修改商品标签
export const labelchange= (data) => {
    return request({
        url: "/qixiang/product/lable/branch/change",
        method: "post",
        data,
    })
}

// POST/qixianggongyingpingtai_api​/qixiang​/lighting​/scheme​/top
// 照明方案置顶
export const lightingtop= (params) => {
    return request({
        url: "/qixiang/lighting/scheme/top",
        method: "post",
        params,
    })
}

// GET/qixianggongyingpingtai_api​/qixiang​/product​/updateImg
// 接收文件路径
export const updateImg= (data) => {
    return request({
        url: "/qixiang/product/updateImg",
        // 接收文件路径",
        method: "post",
        data,
    })
}